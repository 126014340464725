import fetch from "isomorphic-fetch";

// const API_URL = `http://localhost:8080`;
// const API_URL = `https://api.docuses.in`;
const API_URL = process.env.REACT_APP_API_URL || `https://apidev.docuses.in`;

export default function callApi(endpoint, method = "post", body, isScores) {
  console.log("testing 1", endpoint);
  console.log("testing 2", body);

  let token = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : "";
  let headers = {};
  headers["content-type"] = "application/json";
  if (token && token !== "") {
    headers.Authorization = `Bearer ${token}`;
  }
  return fetch(`${API_URL}/${endpoint}`, {
    headers: headers,
    method,
    body: JSON.stringify(body),
  })
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
    .then(
      (response) => response,
      (error) => error
    );
}
